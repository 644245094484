import React, { useRef, useEffect } from "react";
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackgroundImage from 'gatsby-background-image';
// animations
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animSetting } from "@hooks/animations";

import  "./assets/_index.scss";

import Breadcrumbs from "@components/breadcrumbs/Breadcrumb";
import ContentHeaderImageBtns from "@components/modules/content-header-image/_content-header-image-btns";

// Force plugin to not get dropped during build
gsap.registerPlugin(ScrollTrigger);

const imageAlign = (props) => {
    let selectedAlignmentMobile,
        selectedAlignmentTablet,
        selectedAlignmentDesktop

    selectedAlignmentMobile = props.mobileImageAlign && props.mobileImageAlign !== undefined ? `section-content-header-image-position-${props.mobileImageAlign}-mobile` : ''
    selectedAlignmentTablet = props.tabletImageAlign && props.tabletImageAlign !== undefined ? `section-content-header-image-position-${props.tabletImageAlign}-tablet` : ''
    selectedAlignmentDesktop = props.desktopImageAlign && props.desktopImageAlign !== undefined ? `section-content-header-image-position-${props.desktopImageAlign}-desktop` : ''

    return `${selectedAlignmentDesktop} ${selectedAlignmentTablet} ${selectedAlignmentMobile}`
}

const ContentHeaderImageBtn = (props) => {
    let contAnim = useRef(null);
    let titleAnim = useRef(null);
    let btnsAnim = useRef(null);
    let breadAnim = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({
            defaults: {
                duration: animSetting.duration,
                ease: animSetting.ease,
            }
        })
        .to(contAnim, {
            duration: 0.4,
            css: {visibility: 'visible'}
        })
        .from(titleAnim, {
            opacity: 0,
            y: 100,
            delay: 0.3
        })
        .from(btnsAnim.current, {
            opacity: 0,
            y: -50,
            delay: 0.3
        })
        if (!props.HideBreadcrumbs) {
            tl.from(breadAnim, {
                opacity: 0,
                delay: 0.3
            })
        }
    }, [props.HideBreadcrumbs])

    return(
        <BackgroundImage
        Tag="section"
        className={`section section-content-header-image${props.sectionClass} ${imageAlign(props)}`}
        fluid={props.imgUrl}
        // preserveStackingContext={true}
        critical={true}
        role="img"
        >
            <div className="overlay"></div>
            <div className="section-content-header-image__content mb-32 mb-sm-52 mb-xl-82" ref={el => contAnim = el}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            {!props.HideBreadcrumbs &&
                            <div ref={el => breadAnim = el}>
                                <Breadcrumbs
                                    className={props.breadcrumbsClass}
                                    pageName={props.pageName}
                                    pageName_1={props.pageName_1}
                                    pageLink_1={props.pageLink_1}
                                    lastClass_1={props.lastClass_1}
                                    pageName_2={props.pageName_2}
                                    pageLink_2={props.pageLink_2}
                                    lastClass_2={props.lastClass_2}
                                    pageName_3={props.pageName_3}
                                    pageLink_3={props.pageLink_3}
                                    pageArray={props.pageArray}
                                />
                            </div>
                            }

                            <h1 className="section-content-header-image__title h4 mb-20 mb-sm-40" ref={el => titleAnim = el}>
                                {props.Title}
                            </h1>

                            {
                                props.ButtonData && 
                                <ContentHeaderImageBtns
                                    btnContainerClass="section-content-header-image__title h4 mb-20 mb-sm-40"
                                    btnClass={props.btnClass}
                                    ButtonData={props.ButtonData}
                                    ref={btnsAnim}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </BackgroundImage>
    )
}

// Check all the prop types
ContentHeaderImageBtn.propTypes = {
    sectionClass: PropTypes.string,
    HideBreadcrumbs: PropTypes.bool,
    breadcrumbsClass: PropTypes.string,
    pageName: PropTypes.string,
    pageName_1: PropTypes.string,
    pageLink_1: PropTypes.string,
    lastClass_1: PropTypes.string,
    pageName_2: PropTypes.string,
    pageLink_2: PropTypes.string,
    lastClass_2: PropTypes.string,
    Title: PropTypes.string,
    btnClass: PropTypes.string,
    ButtonData: PropTypes.array,
    ButtonLabel: PropTypes.string,
    ButtonLink: PropTypes.string,
    btnClass2: PropTypes.string,
    ButtonLabel2: PropTypes.string,
    ButtonLink2: PropTypes.string
};

// Specifies the default values for props:
ContentHeaderImageBtn.defaultProps = {
    sectionClass: '',
    HideBreadcrumbs: false,
    breadcrumbsClass: ' section-breadcrumb-white',
    pageName: 'Home',
    pageName_1: '',
    pageLink_1: '',
    lastClass_1: '',
    pageName_2: '',
    pageLink_2: '',
    lastClass_2: '',
    Title: 'Our experienced team will provide the right career opportunities for you.',
    btnClass: 'btn btn-primary',
    ButtonData: [
        {
            id: 1231,
            targetLink: '#',
            ButtonLabel: 'Upload Your CV',
            ButtonLink: '/',
            btnClass: 'btn btn-primary'
        },
        {
            id: 1231,
            targetLink: '#',
            ButtonLabel2: 'Register as a Candidate',
            ButtonLink2: '/',
            btnClass: 'btn btn-primary'
        }
    ],
};

export default ContentHeaderImageBtn